<template>
  <div>
    <div>
      <ElXlsx @upData="upData" />
    </div>
    <el-table :data="tableData" border style="width: 100%">
      <el-table-column prop="type" label="区域">
      </el-table-column>
      <el-table-column prop="contractBudgets" label="合同预算">
      </el-table-column>
      <el-table-column prop="contractBicycle" label="合同完成值">
      </el-table-column>
      <el-table-column prop="contractCompletionRates" label="合同完成率">
      </el-table-column>
      <el-table-column prop="incomeBudgets" label="收入预算">
      </el-table-column>
      <el-table-column prop="incomeBicycle" label="收入完成值">
      </el-table-column>
      <el-table-column prop="incomeCompletionRates" label="收入完成率">
      </el-table-column>
      <el-table-column prop="grossRates" label="毛利预算">
      </el-table-column>
      <el-table-column prop="grossTotle" label="毛利完成值">
      </el-table-column>
      <el-table-column prop="grossCompletionRates" label="毛利完成率">
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
  import ElXlsx from '../../components/ElXlsx.vue'
  export default {
    components: {
      ElXlsx
    },
    inject: ['reload'],
    data() {
      return {
        tableData: [],
        items: ['type', 'contractBudgets', 'contractBicycle', 'contractCompletionRates', 'incomeBudgets', 'incomeBicycle' ,'incomeCompletionRates', 'grossRates', 'grossTotle', 'grossCompletionRates']
      }
    },
    mounted() {
      this.$axios.post('/area-contract-income-gross/findList').then(res => {
        this.tableData = res.data.data
      })
    },
    methods: {
      upData(e) {
        let dataArr = this.$replaceString(e, this.items)
        this.$axios.post('/area-contract-income-gross/save', dataArr).then(res => {
          this.$message({
            message: '导入成功。',
            type: 'success'
          })
          this.reload()
        })
      },
    }
  }
</script>

<style scoped>
  .el-table {
    margin-top: 20px;
  }
</style>
